      
<template>
    <div id="qrRenderX2" class="col-sm-8">
        <div class="row">
            <div class="col-sm-6">
                <img src="@/assets/images/client_logo_alpha.webp" alt height="70" />
                <h4><strong>Members Card</strong></h4>
            </div>
            <div class="col-sm-6">
                <div id="renderDiv">
                    <div id="qrGen">
                        <canvas id="genCanvas"></canvas>
                        {{ renderQrCode('genCanvas', memberData.member_number) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <h5>Member Name: <strong>{{ memberData.member_name }}</strong></h5>
                <h5>Member No: <strong>{{ memberData.member_number }}</strong></h5>
                <h5>Membership Date: <strong>{{ memberData.accepted_membership_date | humanReadableDate }}</strong></h5>
            </div>
        </div>
    </div>

</template>

    
<script>
import * as html2pdf from "../../plugins/html2pdf/html2pdf.bundle.min";
import QRCode from 'qrcode'
export default {
    data: () => ({
        generateOn: false
    }),
    props: {
        memberData: {
            required: true 
        }
    },
    filters: {
        humanReadableDate(value) {
            if (!value) return '';

            const date = new Date(value);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };

            return date.toLocaleDateString('en-US', options);
        },
    },
    methods: {
        renderQrCode(element, qrStr) {
            setTimeout(function () {
                let canvas = document.getElementById(element);
                QRCode.toCanvas(canvas, qrStr, { errorCorrectionLevel: 'L', width: 125, margin: 1 }, function (error) {
                    //eslint-disable-next-line
                    if (error) console.error(error);
                });
            }, 150);
        },
        exportCard() {
            setTimeout(() => {
                let element = document.getElementById('qrRenderX2');
                let opt = {
                    margin: [0, 0, 0, 0], // top, left, bottom, right
                    filename: 'membercard.pdf',
                    image: { type: "jpeg", quality: 1 },
                    html2canvas: { scale: window.devicePixelRatio, logging: true },
                    jsPDF: { unit: "mm", format: [90, 180], orientation: "landscape" },
                };
                let htmlWorker = html2pdf();
                htmlWorker.set(opt).from(element).save();
                this.generateOn = false;
            }, 150);
        }
    },
    created: function () {
        this.$eventHub.$on('renderCard', this.exportCard);
    },
    beforeDestroy: function () {
        this.$eventHub.$off('renderCard');
    }
};
</script>
<style lang="scss" scoped>
#renderDiv {
    display: inline !important;
    margin-top: 8px;
}
.rotate {
    transform: rotate(-90deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
}
</style>